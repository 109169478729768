/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ProofOfEfficiency,
  ProofOfEfficiencyInterface,
} from "../ProofOfEfficiency";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IPolygonZkEVMGlobalExitRootV2",
        name: "_globalExitRootManager",
        type: "address",
      },
      {
        internalType: "contract IERC20Upgradeable",
        name: "_pol",
        type: "address",
      },
      {
        internalType: "contract IPolygonZkEVMBridgeV2",
        name: "_bridgeAddress",
        type: "address",
      },
      {
        internalType: "contract PolygonRollupManager",
        name: "_rollupManager",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "BatchAlreadyVerified",
    type: "error",
  },
  {
    inputs: [],
    name: "BatchNotSequencedOrNotSequenceEnd",
    type: "error",
  },
  {
    inputs: [],
    name: "ExceedMaxVerifyBatches",
    type: "error",
  },
  {
    inputs: [],
    name: "FinalNumBatchBelowLastVerifiedBatch",
    type: "error",
  },
  {
    inputs: [],
    name: "FinalNumBatchDoesNotMatchPendingState",
    type: "error",
  },
  {
    inputs: [],
    name: "FinalPendingStateNumInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "ForceBatchNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ForceBatchTimeoutNotExpired",
    type: "error",
  },
  {
    inputs: [],
    name: "ForceBatchesAlreadyActive",
    type: "error",
  },
  {
    inputs: [],
    name: "ForceBatchesOverflow",
    type: "error",
  },
  {
    inputs: [],
    name: "ForcedDataDoesNotMatch",
    type: "error",
  },
  {
    inputs: [],
    name: "GasTokenNetworkMustBeZeroOnEther",
    type: "error",
  },
  {
    inputs: [],
    name: "GlobalExitRootNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "HaltTimeoutNotExpired",
    type: "error",
  },
  {
    inputs: [],
    name: "HugeTokenMetadataNotSupported",
    type: "error",
  },
  {
    inputs: [],
    name: "InitNumBatchAboveLastVerifiedBatch",
    type: "error",
  },
  {
    inputs: [],
    name: "InitNumBatchDoesNotMatchPendingState",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitializeTransaction",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidProof",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidRangeBatchTimeTarget",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidRangeForceBatchTimeout",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidRangeMultiplierBatchFee",
    type: "error",
  },
  {
    inputs: [],
    name: "NewAccInputHashDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "NewPendingStateTimeoutMustBeLower",
    type: "error",
  },
  {
    inputs: [],
    name: "NewStateRootNotInsidePrime",
    type: "error",
  },
  {
    inputs: [],
    name: "NewTrustedAggregatorTimeoutMustBeLower",
    type: "error",
  },
  {
    inputs: [],
    name: "NotEnoughMaticAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "NotEnoughPOLAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "OldAccInputHashDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "OldStateRootDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyAdmin",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyPendingAdmin",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyRollupManager",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyTrustedAggregator",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyTrustedSequencer",
    type: "error",
  },
  {
    inputs: [],
    name: "PendingStateDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "PendingStateInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "PendingStateNotConsolidable",
    type: "error",
  },
  {
    inputs: [],
    name: "PendingStateTimeoutExceedHaltAggregationTimeout",
    type: "error",
  },
  {
    inputs: [],
    name: "SequenceZeroBatches",
    type: "error",
  },
  {
    inputs: [],
    name: "SequencedTimestampBelowForcedTimestamp",
    type: "error",
  },
  {
    inputs: [],
    name: "SequencedTimestampInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "StoredRootMustBeDifferentThanNewRoot",
    type: "error",
  },
  {
    inputs: [],
    name: "TransactionsLengthAboveMax",
    type: "error",
  },
  {
    inputs: [],
    name: "TrustedAggregatorTimeoutExceedHaltAggregationTimeout",
    type: "error",
  },
  {
    inputs: [],
    name: "TrustedAggregatorTimeoutNotExpired",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "AcceptAdminRole",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "ActivateForceBatches",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "forceBatchNum",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "lastGlobalExitRoot",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "sequencer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "transactions",
        type: "bytes",
      },
    ],
    name: "ForceBatch",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes",
        name: "transactions",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "lastGlobalExitRoot",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "sequencer",
        type: "address",
      },
    ],
    name: "InitialSequenceBatches",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "l1InfoRoot",
        type: "bytes32",
      },
    ],
    name: "SequenceBatches",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
    ],
    name: "SequenceForceBatches",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "newforceBatchTimeout",
        type: "uint64",
      },
    ],
    name: "SetForceBatchTimeout",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newTrustedSequencer",
        type: "address",
      },
    ],
    name: "SetTrustedSequencer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "newTrustedSequencerURL",
        type: "string",
      },
    ],
    name: "SetTrustedSequencerURL",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newPendingAdmin",
        type: "address",
      },
    ],
    name: "TransferAdminRole",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "stateRoot",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "aggregator",
        type: "address",
      },
    ],
    name: "VerifyBatches",
    type: "event",
  },
  {
    inputs: [],
    name: "GLOBAL_EXIT_ROOT_MANAGER_L2",
    outputs: [
      {
        internalType: "contract IBasePolygonZkEVMGlobalExitRoot",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIALIZE_TX_BRIDGE_LIST_LEN_LEN",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIALIZE_TX_BRIDGE_PARAMS",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIALIZE_TX_BRIDGE_PARAMS_AFTER_BRIDGE_ADDRESS",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIALIZE_TX_BRIDGE_PARAMS_AFTER_BRIDGE_ADDRESS_EMPTY_METADATA",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIALIZE_TX_CONSTANT_BYTES",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIALIZE_TX_CONSTANT_BYTES_EMPTY_METADATA",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIALIZE_TX_DATA_LEN_EMPTY_METADATA",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INITIALIZE_TX_EFFECTIVE_PERCENTAGE",
    outputs: [
      {
        internalType: "bytes1",
        name: "",
        type: "bytes1",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SIGNATURE_INITIALIZE_TX_R",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SIGNATURE_INITIALIZE_TX_S",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SIGNATURE_INITIALIZE_TX_V",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "acceptAdminRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "activateForceBatches",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "admin",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bridgeAddress",
    outputs: [
      {
        internalType: "contract IPolygonZkEVMBridgeV2",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "calculatePolPerForceBatch",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "transactions",
        type: "bytes",
      },
      {
        internalType: "uint256",
        name: "polAmount",
        type: "uint256",
      },
    ],
    name: "forceBatch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "forceBatchTimeout",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    name: "forcedBatches",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "gapLastTimestamp",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "gasTokenAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "gasTokenNetwork",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "networkID",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "_gasTokenAddress",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "_gasTokenNetwork",
        type: "uint32",
      },
      {
        internalType: "bytes",
        name: "_gasTokenMetadata",
        type: "bytes",
      },
    ],
    name: "generateInitializeTransaction",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "globalExitRootManager",
    outputs: [
      {
        internalType: "contract IPolygonZkEVMGlobalExitRootV2",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_admin",
        type: "address",
      },
      {
        internalType: "address",
        name: "sequencer",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "networkID",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "_gasTokenAddress",
        type: "address",
      },
      {
        internalType: "string",
        name: "sequencerURL",
        type: "string",
      },
      {
        internalType: "string",
        name: "_networkName",
        type: "string",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isForcedBatchAllowed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastAccInputHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastForceBatch",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastForceBatchSequenced",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "networkName",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "lastVerifiedBatch",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "newStateRoot",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "aggregator",
        type: "address",
      },
    ],
    name: "onVerifyBatches",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingAdmin",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pol",
    outputs: [
      {
        internalType: "contract IERC20Upgradeable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rollupManager",
    outputs: [
      {
        internalType: "contract PolygonRollupManager",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "transactions",
            type: "bytes",
          },
          {
            internalType: "bytes32",
            name: "forcedGlobalExitRoot",
            type: "bytes32",
          },
          {
            internalType: "uint64",
            name: "forcedTimestamp",
            type: "uint64",
          },
          {
            internalType: "bytes32",
            name: "forcedBlockHashL1",
            type: "bytes32",
          },
        ],
        internalType: "struct PolygonRollupBaseEtrog.BatchData[]",
        name: "batches",
        type: "tuple[]",
      },
      {
        internalType: "address",
        name: "l2Coinbase",
        type: "address",
      },
    ],
    name: "sequenceBatches",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "transactions",
            type: "bytes",
          },
          {
            internalType: "bytes32",
            name: "forcedGlobalExitRoot",
            type: "bytes32",
          },
          {
            internalType: "uint64",
            name: "forcedTimestamp",
            type: "uint64",
          },
          {
            internalType: "bytes32",
            name: "forcedBlockHashL1",
            type: "bytes32",
          },
        ],
        internalType: "struct PolygonRollupBaseEtrog.BatchData[]",
        name: "batches",
        type: "tuple[]",
      },
    ],
    name: "sequenceForceBatches",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "newforceBatchTimeout",
        type: "uint64",
      },
    ],
    name: "setForceBatchTimeout",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newTrustedSequencer",
        type: "address",
      },
    ],
    name: "setTrustedSequencer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newTrustedSequencerURL",
        type: "string",
      },
    ],
    name: "setTrustedSequencerURL",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newPendingAdmin",
        type: "address",
      },
    ],
    name: "transferAdminRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "trustedSequencer",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "trustedSequencerURL",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ProofOfEfficiency__factory {
  static readonly abi = _abi;
  static createInterface(): ProofOfEfficiencyInterface {
    return new utils.Interface(_abi) as ProofOfEfficiencyInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ProofOfEfficiency {
    return new Contract(address, _abi, signerOrProvider) as ProofOfEfficiency;
  }
}
